.firstPage {
  /* background-image: url('./assets/1.jpg'); */
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.secondPage {
  background-image: url('./assets/44.png');
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.ThirdPage {
  background-image: url('./assets/1.mp4');
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}
.Section4 {
  /* background-image: url('./assets/3.jpg'); */
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.footer {

  background-color: #f9f9f9;
}

.section-content {
  text-align: center;
  color: white;
}

.custom-button {
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 16px;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.custom-button:hover {
  background-color: rgba(0, 0, 0, 0.7);
}
